<template>
  <div>
     
        <div class="row">
            <div class="col-md-12">
                <h3 v-if="pgLoading" class="bottom40 margin40 webkit-animation webkit-50per-50"></h3>
                <h3 v-if="!pgLoading" 
                    class="bottom40 margin40"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria rtl' : ''">
                    {{ $t('app.featured_news') }}
                </h3>
            </div>
        </div>


        <div v-if="pgLoading">
            <div class="row bottom20" v-for="(item, idx) in 3" :key="idx">

                <FeaturedLoading></FeaturedLoading>

            </div>
        </div>

        <div v-if="!pgLoading">
            <div class="row bottom20" v-for="(item, indx) in items" :key="indx">
                
                <FeaturedCard :item="item" :refs="refs"></FeaturedCard>

            </div>
        </div>

  </div>
</template>

<script>
export default {
    name: 'FeaturedAside',
    props: [
        'title',
        'refs',
        'paginate'
    ],
    components: {
        FeaturedLoading: () => import('@/components/FeaturedLoading.vue'),
        FeaturedCard: () => import('@/components/FeaturedCard.vue')
    },
    data(){
        return {
            //
            pgLoading: true,
            items: []
        }
    },
    created() {
        //
        this.fetchData();
    },
    methods: {
        //
        fetchData() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/'+this.refs,
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.items = res.data.items;
            })
            .catch(() => {})
            .finally(() => {});
        },
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
